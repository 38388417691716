import React, { useState } from 'react';
import SubscribeImg from '../images/subscribe.png';
import axios from 'axios'
import Popup from 'reactjs-popup';

const Subscribe = () => {
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [open, setOpen] = useState(false);
    const validateEmail = () => {
        // Basic email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setEmailError('Geçerli bir email adresi giriniz.');
        } else {
            setEmailError('');
        }
    };

    const validatePhone = () => {
        // Basic phone number validation (assuming it's a Turkish phone number)
        const phoneRegex = /^[0-9]{10}$/;
        if (!phoneRegex.test(phone)) {
            setPhoneError('Geçerli bir telefon numarası giriniz.');
        } else {
            setPhoneError('');
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Validate email and phone before submitting
        validateEmail();
        validatePhone();

        // If both email and phone are valid, you can submit the form or perform other actions here
        if (!emailError && !phoneError) {
            // Your form submission logic goes here
            console.log('Form submitted:', email, phone);
            onSubmit({
                email: email,
                phone: phone,
            }, "Abonelik Formu")
        }
    };

    const onSubmit = async (data, subject) => {
        try {
            const result = await axios.post('https://2bzf0lvy95.execute-api.us-east-1.amazonaws.com/api/form', {
                subject: subject,
                data: data
            },
                {
                    headers: {
                        "Content-Type": "application/json"
                    }

                }
            );
            setOpen(true)
            setEmail('')
            setPhone('')
            setTimeout(() => {
                setOpen(false)
            }, 2500);
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <div className='p-16 lg:flex grid justify-center items-center gap-x-[119px] gap-y-8'>
                <div className='flex flex-col'>
                    <img src={SubscribeImg} alt='' />
                    <h3 className='lg:text-[#717171] text-black text-center'>
                        Destek olmak ve çalışmalarımızdan <br />
                        haberdar olmak için bültenimize abone olun.
                    </h3>
                </div>
                <div className=''>
                    <form action='#' method='POST' className='mx-auto' onSubmit={handleSubmit}>
                        <div className='mb-5'>
                            <input
                                className={`block lg:w-[595px] w-[303px] mx-auto h-[52px] border border-[#A5A5A5] py-1.5 pl-7 pr-20 text-gray-900 ring-gray-300 placeholder:text-[#8E8E8E] sm:text-sm sm:leading-6 ${emailError ? 'border-red-500' : ''
                                    }`}
                                type='email'
                                name='email'
                                id='email'
                                placeholder='Email Adresiniz'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                onBlur={validateEmail}
                            />
                            {emailError && (
                                <p className='text-red-500 text-sm mt-1'>{emailError}</p>
                            )}
                        </div>
                        <div className='mb-3'>
                            <input
                                className={`block lg:w-[595px] w-[303px] mx-auto h-[52px] border border-[#A5A5A5] py-1.5 pl-7 pr-20 text-gray-900 ring-gray-300 placeholder:text-[#8E8E8E] sm:text-sm sm:leading-6 ${phoneError ? 'border-red-500' : ''
                                    }`}
                                type='tel'
                                name='tel'
                                id='tel'
                                placeholder='Telefon Numaranız'
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                onBlur={validatePhone}
                            />
                            {phoneError && (
                                <p className='text-red-500 text-sm mt-1'>{phoneError}</p>
                            )}
                        </div>
                        <p className='text-[#969696] lg:text-sm text-xs text-center'>
                            Kişisel verilere ilişkin aydınlatma metnine{' '}
                            <a href='#' className='text-sadik-blue'>
                                buradan
                            </a>{' '}
                            ulaşabilirsiniz.
                        </p>
                        <button
                            className='bg-sadik-blue hover:bg-sadik-yellow p-2 px-3 w-[164px] mt-4 text-white flex justify-center mx-auto'
                            type='submit'
                        >
                            Abone Ol
                        </button>
                    </form>
                </div>
            </div>
            <Popup open={open} modal contentStyle={{
                width: 'auto',
                padding: '0px',
                borderWidth: '0px',
                borderRadius: '9px',
            }}>
                <div className="relative w-full max-w-md h-full md:h-auto">
                    <div className="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
                        <button
                            onClick={() => setOpen(false)}
                            type="button"
                            className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="successModal">
                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                        <div className="w-12 h-12 rounded-full bg-green-100 dark:bg-green-900 p-2 flex items-center justify-center mx-auto mb-3.5">
                            <svg aria-hidden="true" className="w-8 h-8 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path></svg>
                            <span className="sr-only">Success</span>
                        </div>
                        <p className="mb-4 text-lg font-semibold text-gray-900 dark:text-white">Abonelik Talebiniz Alınmıştır!</p>
                    </div>
                </div>
            </Popup>
        </>
    );
};

export default Subscribe;
