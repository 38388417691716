import React,{useState}  from 'react'

import Logo from '../images/logo.svg'
import BurgerMenu from '../images/burger-menu.svg'
import CloseBurgerMenu from '../images/close-burger-menu.svg'

export const Navbar = () => {

    const [burger, setBurger] = useState('closed'); 

  return (
<>
    <nav className="bg-black bg-opacity-20 z-20 px-4 py-4 fixed w-full top-0">
        <div className="max-w-[1280px] mx-auto justify-between items-center">
            <div className="flex text-white font-bold items-center">
                <div className='flex-1'>
                    <a href="/"><img className='inline-block pr-4 pb-1' src={Logo} alt="" /></a>
                </div>
                <div className='md:flex gap-8 float-right hidden'>
                    <a href="/about"><p className='p-2 hover:text-sadik-yellow'>Hakkımda</p></a>
                    <a href="/projects"><p className='p-2 hover:text-sadik-yellow'>Projeler</p></a>
                    <a href="/volunteer"><p className='p-2 hover:text-sadik-yellow'>Gönüllü Ol</p></a>
                    <a href="/project"><button className='bg-sadik-blue hover:bg-sadik-yellow p-2 px-3'><p>Projem Var</p></button></a>
                    <a href="/contact-me"><button className='bg-transparent hover:border-sadik-yellow hover:text-sadik-yellow border-[2px] border-white p-[6px] px-3'><p>İletişim</p></button></a>
                </div>
                <div className='flex float-right md:hidden block'>
                    <button onClick={(e) => burger == 'closed' ? setBurger('opened') : setBurger('closed')}>
                        <img src={burger == 'closed' ? BurgerMenu : CloseBurgerMenu} alt="" />
                    </button>
                </div>
            </div>
        </div>
    </nav>
    <div className={`menu-gradient h-screen w-full pt-24 px-10 fixed top-0 z-10 text-white ${burger == 'closed' ? 'hidden' : 'block'}`}>
        <a href="/about"><p className='pl-4'>Hakkımızda</p></a>
        <hr className='border-white max-w-[300px] md:hidden block mt-2 py-2' />
        <a href="/projects"><p className='pl-4'>Projeler</p></a>
        <hr className='border-white max-w-[300px] md:hidden block mt-2 py-2' />
        <a href="/volunteer"><p className='pl-4'>Gönüllü Ol</p></a>
        <hr className='border-white max-w-[300px] md:hidden block mt-2 py-2' />
        <a href="/project"><p className='pl-4'>Projem Var</p></a>
        <hr className='border-white max-w-[300px] md:hidden block mt-2 py-2' />
        <a href="/contact-me"><p className='pl-4'>İletişim</p></a>
    </div>
 </>
  )
}
