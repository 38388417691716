import React,{useState,useEffect} from 'react'

import Banner from '../images/homepage-banner.png'
import BannerM from '../images/homepage-mobile.png'
import RoundedPhoto from '../images/rounded-photo.svg'
import RoundedPhoto2 from '../images/rounded-photo2.svg'
import RoundedPhoto3 from '../images/rounded-photo3.svg'
import HomePhoto from '../images/homepage-photo.png'
import HomePhoto2 from '../images/homepage-photo2.png'
import HomePhotoBG from '../images/homepage-image-bg.png'
import Subscribe from '../components/Subscribe';

import News0 from '../images/news-images/desktop.png'
import News1 from '../images/news-images/desktop-1.png'
import News2 from '../images/news-images/desktop-2.png'
import News3 from '../images/news-images/desktop-3.png'
import News4 from '../images/news-images/desktop-4.png'
import News5 from '../images/news-images/desktop-5.png'
import News6 from '../images/news-images/desktop-6.png'
import News7 from '../images/news-images/desktop-7.png'
import News8 from '../images/news-images/desktop-8.png'

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

export const Homepage = () => {

    const [bcgImg, setBcgImg] = useState(HomePhoto)
    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
      ]);
    
      useEffect(() => {
        const handleWindowResize = () => {
          setWindowSize([window.innerWidth]);
        };
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      }, []);

  return (
    <>
    <div className={"container-full md:h-[713px] h-[411px] relative bg-no-repeat bg-center text-white p-4"}  style={windowSize > 768 ?{ backgroundImage: `url("${Banner}")` } : { backgroundImage: `url("${BannerM}")` } }>
        <div className='max-w-[1280px] mx-auto md:pt-56 pt-20'>
            <p className='sm:text-5xl text-4xl font-bold max-w-[550px]'>Sadık Güvenç <span className='text-sadik-yellow'>Tarih Yazmaya</span> Geliyor!</p>
            <p className='pt-4 sm:text-lg max-w-[600px]'>Gebze için tutulmayan sözleri hatırlatmaya, benzersiz projeleri gerçekleştirmeye geliyorum. <span className='font-bold'>Gebze ile Tarih Yazmaya Geliyorum.</span></p>
            <div className='pt-4 flex gap-4'>
                <a href="/project"><button className='bg-sadik-blue hover:bg-sadik-yellow p-2 px-3'><p>Projem Var</p></button></a>
                <a href="/contact-me"><button className='bg-transparent hover:border-sadik-yellow hover:text-sadik-yellow border-[2px] border-white p-[6px] px-3'><p>İletişim</p></button></a>
            </div>
        </div>
    </div>
    <div>
        <div className='homepage-area max-w-[1920px] md:h-[300px] mx-auto text-white font-bold text-xl pt-4'>
            <div className='md:flex justify-center items-center md:pt-24 md:text-left text-center'>
                <p>Sağlıklı Yaşam <span className='text-[#FDDB00] px-2 md:inline-block hidden'>▶</span></p>
                <hr className='border-white max-w-[300px] mx-auto md:hidden block mt-2 py-2' />
                <p>Tecrübe Edinmek <span className='text-[#FDDB00] px-2 md:inline-block hidden'>▶</span></p>
                <hr className='border-white max-w-[300px] mx-auto md:hidden block mt-2 py-2' />
                <p>Katılımcı Demokrasi <span className='text-[#FDDB00] px-2 md:inline-block hidden'>▶</span></p>
                <hr className='border-white max-w-[300px] mx-auto md:hidden block mt-2 py-2' />
                <p>Kültür ve Sanat</p>
                <hr className='border-white max-w-[300px] mx-auto md:hidden block mt-2 py-2' />
            </div>
            <div className='md:flex justify-center items-center md:pt-6 md:text-left text-center pb-4'>
                <p>Toplumsal Kalkınma <span className='text-[#FDDB00] px-2 md:inline-block hidden'>▶</span></p>
                <hr className='border-white max-w-[300px] mx-auto md:hidden block mt-2 py-2' />
                <p>Sosyal Haklar <span className='text-[#FDDB00] px-2 md:inline-block hidden'>▶</span></p>
                <hr className='border-white max-w-[300px] mx-auto md:hidden block mt-2 py-2' />
                <p>Doğaya Hürmet</p>
            </div>
        </div>
    </div>

    <div className='max-w-[1280px] mx-auto py-10'>
        <p className='text-2xl text-center mx-2'>Durmaksızın Çalışmak <span className='font-bold'>#GebzeyeDeğer</span></p>

        <div className='grid md:grid-cols-3 gap-2 pt-4 md:mx-0 mx-4'>
            <div>
                <div className='relative'>
                    <img className='mx-auto' src={News0} alt="" />
                    <div className='bottom-center w-full'>
                        <div className='bg-black bg-opacity-50 text-white md:w-11/12 w-full p-2 text-xs mx-auto'>Gebze için canla başla birlikte çalışacağız. </div>
                    </div>
                </div>
                <div className='grid grid-cols-2 gap-2 pt-2 md:px-3'>
                    <div className='relative'>
                        <img className='mx-auto' src={News3} alt="" />
                        <div className='bottom-center w-full'>
                            <div className='bg-black bg-opacity-50 text-white md:w-11/12 w-full p-2 py-[2px] text-xs mx-auto line-clamp-2'>Kentim için, Gebze için, Gebzeliler için tüm gayretimle çalışmaya geliyorum."</div>
                        </div>
                    </div>
                    <div className='relative'>
                        <img className='mx-auto' src={News6} alt="" />
                        <div className='bottom-center w-full'>
                            <div className='bg-black bg-opacity-50 text-white md:w-11/12 w-full p-2 py-[2px] text-xs mx-auto line-clamp-2'>Sosyal belediyecilik olmazsa olmazımızdır.</div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div className='grid grid-cols-2 gap-2 pb-2 md:px-3'>
                    <div className='relative'>
                        <img className='mx-auto' src={News5} alt="" />
                        <div className='bottom-center w-full'>
                            <div className='bg-black bg-opacity-50 text-white md:w-11/12 w-full p-2 py-[2px] text-xs mx-auto line-clamp-2'>Çözüm için tüm hemşehrilerimizin dertlerinin odağında olacağıma dair sözüm sözdür.</div>
                        </div>
                    </div>
                    <div className='relative'>
                        <img className='mx-auto' src={News8} alt="" />
                        <div className='bottom-center w-full'>
                            <div className='bg-black bg-opacity-50 text-white md:w-11/12 w-full p-2 py-[2px] text-xs mx-auto line-clamp-2'>Bu onurlu görev için bizler hazırız.</div>
                        </div>
                    </div>
                </div>
                <div className='relative'>
                    <img className='mx-auto' src={News2} alt="" />
                    <div className='bottom-center w-full'>
                        <div className='bg-black bg-opacity-50 text-white md:w-11/12 w-full p-2 text-xs mx-auto'>İyi belediyecilik samimiyet ve sevgi ile olur.</div>
                    </div>
                </div>
            </div>

            <div>
                <div className='relative'>
                    <img className='mx-auto' src={News1} alt="" />
                    <div className='bottom-center w-full'>
                        <div className='bg-black bg-opacity-50 text-white md:w-11/12 w-full p-2 text-xs mx-auto'>Gebze için canla başla birlikte çalışacağız. Gebze'ye hak ettiği değeri kazandıracağız.</div>
                    </div>
                </div>
                <div className='grid grid-cols-2 gap-2 pt-2 md:px-3'>
                    <div className='relative'>
                        <img className='mx-auto' src={News4} alt="" />
                        <div className='bottom-center w-full'>
                            <div className='bg-black bg-opacity-50 text-white md:w-11/12 w-full p-2 py-[2px] text-xs mx-auto line-clamp-2'>Gebze'ye hak ettiği değeri kazandıracağız.</div>
                        </div>
                    </div>
                    <div className='relative'>
                        <img className='mx-auto' src={News7} alt="" />
                        <div className='bottom-center w-full'>
                            <div className='bg-black bg-opacity-50 text-white md:w-11/12 w-full p-2 py-[2px] text-xs mx-auto line-clamp-2'>7’den 70’e memleket için çalışan herkesin yanında olacağız.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div>

    </div>
    <div>
        <div className='homepage-area2 max-w-[1920px] h-[240px] text-white md:flex md:text-left text-center md:pt-0 pt-8 justify-center items-center'>
            <p className='font-bold text-2xl'>Gebze için Hayal Et Gerçek Olsun!</p>
            <p className='px-6 py-4 max-w-[390px] sm:mx-0 mx-auto text-center'>Gebze için “bir hayalim var” diyorsan paylaş; Gebze için birlikte çalışalım!</p>
            <a href="/project"><button className='bg-transparent hover:border-sadik-yellow hover:text-sadik-yellow border-[2px] border-white p-[6px] px-3'><p>Paylaş</p></button></a>
        </div>
    </div>
    {/* <Carousel autoPlay={true} showThumbs={false} emulateTouch={true} showArrows={true} infiniteLoop={true}>
    <div className='flex justify-center h-[470px] items-center md:text-left text-center flex'>
        <img src={RoundedPhoto} alt="" />
        <p className='text-[#6B6B6B] max-w-[860px] pl-6 italic'>Bir seneyi daha geride bırakırken 2024 yılının Gebzeli hemşehrilerimiz ve tüm vatandaşlarımız için huzur, sağlık, mutluluk ve bolca kazanç getirmesini temenni ediyorum.</p>
    </div>
    <div className='flex justify-center h-[470px] items-center md:text-left text-center flex'>
        <img src={RoundedPhoto2} alt="" />
        <p className='text-[#6B6B6B] max-w-[860px] pl-6 italic'>Gebze için canla başla birlikte çalışacağız. Gebze'ye hak ettiği değeri kazandıracağız. #gebzeyedeğer</p>
    </div>
    <div className='flex justify-center h-[470px] items-center md:text-left text-center flex'>
        <img src={RoundedPhoto3} alt="" />
        <p className='text-[#6B6B6B] max-w-[860px] pl-6 italic'>Uzattığımız her el samimiyetle sıkıca tutuluyor. Çünkü derdimiz ve sevdamız ortaktır bizim.</p>
    </div>
    </Carousel> */}
    <Carousel autoPlay={true} showThumbs={false} emulateTouch={true} showArrows={true} infiniteLoop={true}>
    <div className='grid md:grid-cols-2 justify-center h-[470px] items-center md:text-left text-center'>
        <img className='mx-auto max-w-[300px]' src={RoundedPhoto} alt="" />
        <p className='text-[#6B6B6B] md:mx-0 mx-auto max-w-[460px] mx-2 italic'>Bir seneyi daha geride bırakırken 2024 yılının Gebzeli hemşehrilerimiz ve tüm vatandaşlarımız için huzur, sağlık, mutluluk ve bolca kazanç getirmesini temenni ediyorum.</p>
    </div>
    <div className='grid md:grid-cols-2 justify-center h-[470px] items-center md:text-left text-center'>
        <img className='mx-auto max-w-[300px]' src={RoundedPhoto2} alt="" />
        <p className='text-[#6B6B6B] md:mx-0 mx-auto  max-w-[460px] mx-2 italic'>Gebze için canla başla birlikte çalışacağız. Gebze'ye hak ettiği değeri kazandıracağız. #gebzeyedeğer</p>
    </div>
    <div className='grid md:grid-cols-2 justify-center h-[470px] items-center md:text-left text-center'>
        <img className='mx-auto max-w-[300px]' src={RoundedPhoto3} alt="" />
        <p className='text-[#6B6B6B] md:mx-0 mx-auto  max-w-[460px] mx-2 italic'>Uzattığımız her el samimiyetle sıkıca tutuluyor. Çünkü derdimiz ve sevdamız ortaktır bizim.</p>
    </div>
    </Carousel>
    <div>
        <div className={"md:grid md:grid-cols-2 max-w-[1280px] mx-auto items-center relative bg-no-repeat bg-center text-white p-4"} style={{ backgroundImage: `url("${HomePhotoBG}")` }}>
            <div 
                onMouseEnter={() => setBcgImg(HomePhoto2)}
                onMouseLeave={() => setBcgImg(HomePhoto)}
            >
                <img className='mx-auto' src={bcgImg} alt="" />
            </div>
            <div className='text-white text-center'>
                <p className='italic font-bold text-2xl'>Gönüllü Olun</p>
                <p className='py-4 md:pr-4'>Yeni parti çalışmalarında bize katkı sağlamak istiyorsanız siz de gönüllüler grubumuza katılabilirsiniz.</p>
                <a href="/volunteer"><button className='bg-transparent hover:border-sadik-yellow hover:text-sadik-yellow border-[2px] border-white p-[6px] px-3'><p>Başvuru Formu</p></button></a>
            </div>
        </div>
    </div>
    <Subscribe />
    </>
  )
}
