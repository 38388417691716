import React, { useState } from 'react'
import { Tab } from '@headlessui/react'

import Subscribe from '../components/Subscribe';

import AboutBanner from '../images/about-banner.png'
import CareerProfil from '../images/career-sadik.png'
const About = () => {

    const [selectedTab, setSelectedTab] = useState(0);

    return (
        <>
            <div className={"container-full h-[713px] bg-no-repeat bg-center text-black"} style={{ backgroundImage: `url("${AboutBanner}")` }}>

            </div>
            <div className='py-[44px] md:h-[400px] max-w-[1280px] mx-auto  text-[#606060]'>
                <Tab.Group selectedIndex={selectedTab} onChange={setSelectedTab}>
                    <Tab.List className={'flex gap-x-3 mb-4 justify-center'}>
                        <Tab
                            className={({ selected }) =>
                                selected
                                    ? 'border-b border-b-sadik-blue  py-2 px-4  cursor-pointer outline-none'
                                    : 'border-b py-2 px-4  cursor-pointer'
                            }
                        >
                            Sadık Güvenç Kimdir?
                        </Tab>
                        <Tab
                            className={({ selected }) =>
                                selected
                                    ? 'border-b border-b-sadik-blue  py-2 px-4  cursor-pointer outline-none'
                                    : 'text-rot3PayGray py-2 px-4  cursor-pointer'
                            }
                        >
                            Sosyal Hayatım
                        </Tab>
                        <Tab
                            className={({ selected }) =>
                                selected
                                    ? 'border-b border-b-sadik-blue  py-2 px-4  cursor-pointer outline-none'
                                    : 'text-rot3PayGray py-2 px-4  cursor-pointer'
                            }
                        >
                            Siyasi Hayatım
                        </Tab>


                    </Tab.List>
                    <Tab.Panels className={`p-4`}>
                        <Tab.Panel>
                           <p>17 Aralık 1969 tarihinde Gebze'nin Tavşanlı Köyü'nde doğdum. İlkokul ve lise eğitimimi Gebze'de tamamladım. Evli ve üç çocuk babasıyım. Aile şirketimiz Gebze'de lojistik, sanayi, inşaat vb. alanlarda üç nesildir faliyet göstermektedir. 
                            <br/><br/>
                            Kısacası ben, Gebze'de doğan, Gebze'de büyüyen ve Gebze'de ticaretle uğraşan Gebze'nin her metrekaresinde ayak izleri olan Gebzeli Sadık. Yıllardır hak ettiği değeri göremeyen Gebzemizin ve burada yaşayan vatandaşlarımızın hak ettiği değeri görmeleri için belediye başkan adaylığına talip oldum. Bu görevin üstesinden birlikte geleceğimize canı gönülden inanıyor ve çıktığımız bu yolda sizlerden destek bekliyorum. Gebze için, Gebze'ye değer katmak için geliyorum.</p>
                        </Tab.Panel>
                        <Tab.Panel>
                        <p>
                        1960 tarihinde üç nesildir inşaat malzemeleri satan aile şirketinde işe başladım. Aile şirketine katıldığımda, faaliyet alanımızı genişleterek lojistik ve sanayi sektörlerine adım attık. İlerleyen yıllarda, aile şirketimizin yönetim kurulu başkanlığını üstlenerek, işlerimizi büyütme ve geliştirme sorumluluğunu üstlendim.
                        <br/><br/>
                        1992 tarihi, hayatımın bir diğer önemli dönemine işaret ediyor; o gün eşim Gülşen ile hayatımı birleştirdik. 1993’te ilk çocuğumuz Oya, 2000’de ortanca çocuğumuz Begüm ve 2003’te son çocuğumuz Eyüp dünyaya geldiler.  
                        <br/><br/>
                        2000-2010 yılları arasında, Gebzespor Futbol Kulübü yöneticiliği ve Gebze Ziraat Odası Yönetim Kurulu üyeliği görevlerini üstlendim. Ayrıca, Gebzeliler Derneği ve Tavşanlı köyü Yardımlaşma derneklerinin kuruluş süreçlerinde yer alarak, bu derneklerin aktif bir şekilde faaliyet göstermelerine katkı sağladım. Gebze'ye değer katmak, şehri tanıtmak ve topluma fayda sağlayacak her türlü organizasyon ve sivil toplum kuruluşunda görev alarak Gebze'ye olan bağlılığımı pekiştirdim.
                        </p>
                        </Tab.Panel>
                        <Tab.Panel>
                        <p>
                        Siyasi kariyerime 1987 yılında Doğru Yol Partisi Gebze Gençlik Kolları'nda başladım. Kısa sürede Gençlik Kolları Başkanlık görevine getirildim. 1991 yılında aynı parti içinde ana kademe ilçe yöneticiliği görevine getirildim ve 4 dönem boyunca bu görevde bulundum.
                        <br/><br/>
                        1997-2014 yılları arasında Tavşanlı köyünde 17  yıl boyunca muhtarlık yaptım. 2014-2023 yılları arasında 9 yıl Gebze Meclis Üyeliği görevini yerine getirip bu süre içinde 2014-2019 yılları arasında Gebze Muhtarlar Masası Koordinatörlüğünü yaptım.
                        </p>
                        </Tab.Panel>
                    </Tab.Panels>
                </Tab.Group>
            </div>
            <div>
                <div className='grid md:grid-cols-3 md:gap-20 max-w-[1240px] mx-auto'>
                    <img className='mx-auto md:w-auto w-64' src={CareerProfil} alt="" />
                    <div className='md:col-span-2 md:p-0 p-4'>
                        <p className='font-bold text-2xl text-[#606060] py-4 md:text-left text-center'>Aktif Kariyerim</p>
                        <div className='flex items-center pb-2'>
                            <span className='bg-sadik-blue text-white p-1 px-3'>1997</span>
                            <span className='bg-sadik-blue text-white ml-2 p-1 px-3'>2014</span>
                            <p className='pl-3'>Tavşanlı Köy Muhtarlığı</p>
                        </div>

                        <div className='flex items-center pb-2'>
                            <span className='bg-sadik-blue text-white p-1 px-3'>2000</span>
                            <span className='bg-sadik-blue text-white ml-2 p-1 px-3'>2010</span>
                            <p className='pl-3'>Gebzespor Futbol Kulübü Yöneticiliği</p>
                        </div>

                        <div className='flex items-center pb-2'>
                            <span className='bg-sadik-blue text-white p-1 px-3'>2000</span>
                            <span className='bg-sadik-blue text-white ml-2 p-1 px-3'>2010</span>
                            <p className='pl-3'>Gebze Ziraat Odası Yönetim Kurulu Üyesi</p>
                        </div>

                        <div className='flex items-center pb-2'>
                            <span className='bg-sadik-blue text-white p-1 px-3'>2014</span>
                            <span className='bg-sadik-blue text-white ml-2 p-1 px-3'>2019</span>
                            <p className='pl-3'>Gebze Muhtarlar Masası Koordinatörlüğü</p>
                        </div>

                        <div className='flex items-center pb-2'>
                            <span className='bg-sadik-blue text-white p-1 px-3'>2014</span>
                            <span className='bg-sadik-blue text-white ml-2 p-1 px-3'>2023</span>
                            <p className='pl-3'>Gebze Meclis Üyeliği</p>
                        </div>

                        <div className='flex items-center pb-2'>
                            <span className='bg-sadik-blue text-white ml-8 p-1 px-3'>2024</span>
                            <p className='pl-12 font-bold'>Gebze Belediye Başkan Adayı </p>
                        </div>
                        
                    </div>
                </div>
                
            </div>

            <Subscribe />
        </>
    )
}

export default About