import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import { LayoutWebsite } from './components/LayoutWebsite';
import { Homepage } from './pages/Homepage';
import ContactMe from './pages/ContactMe';
import Project from './pages/Project';
import Volunteer from './pages/Volunteer';
import About from './pages/About';
import {Projects} from './pages/Projects';
import 'reactjs-popup/dist/index.css';
function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<LayoutWebsite />}>
            <Route path="/" element={<Homepage />} />
            <Route path="about" element={<About />} />
            <Route path="projects" element={<Projects />} />

            {/* <Route path="/opened-signal" element={<OpenedSignal />} /> */}
            <Route path="contact-me" element={<ContactMe />} />
            <Route path="project" element={<Project />} />
            <Route path="volunteer" element={<Volunteer />} />

          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
