import { useState } from 'react'
import FileIcon from '../images/file-icon.svg'
import { useForm } from "react-hook-form"
import axios from 'axios'
import Popup from 'reactjs-popup';

const Contact = ({ heading, subHeading, showInputFile = true, showTextarea, showCheckbox = false, buttonText, formName = 'Form' }) => {
    const [open, setOpen] = useState(false);
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        reset
    } = useForm()

    const onSubmit = async (data) => {
        try {
            const result = await axios.post('https://2bzf0lvy95.execute-api.us-east-1.amazonaws.com/api/form', {
                subject: formName,
                data: data
            });
            setOpen(true)
            reset()
            setTimeout(() => {
                setOpen(false)
            }, 2500);
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <div className='lg:p-16 p-4 bg-[#00112B]'>
                <div className='text-center mb-[24px]'>
                    <h2 className='text-white font-semibold text-2xl'>{heading}</h2>
                    <h3 className='text-white text-lg'>{subHeading}</h3>
                </div>
                <div className='flex justify-center'>
                    <form>
                        <div className='flex gap-x-2 mb-[14px]'>
                            <input
                                className='block lg:w-[390px] w-full  h-[43px] border border-[#A5A5A5] py-1.5 pl-3 text-gray-900 ring-gray-300 placeholder:text-[#8E8E8E] sm:text-sm sm:leading-6'
                                type="text"
                                name="firstname"
                                id="firstname"
                                placeholder='Adınız'
                                {...register("firstname", { required: true })}
                            />
                            <input
                                className='block lg:w-[390px] w-full  h-[43px] border border-[#A5A5A5] py-1.5 pl-3 text-gray-900 ring-gray-300 placeholder:text-[#8E8E8E] sm:text-sm sm:leading-6'

                                type="text"
                                name="lastname"
                                id="lastname"
                                placeholder='Soyadınız'
                                {...register("lastname", { required: true })}
                            />
                        </div>
                        <input
                            className='mb-[14px] block lg:w-[791px] w-full  h-[43px] border border-[#A5A5A5] py-1.5 pl-3 text-gray-900 ring-gray-300 placeholder:text-[#8E8E8E] sm:text-sm sm:leading-6'
                            type="email"
                            name="email"
                            id="email"
                            placeholder='E-Posta Adresiniz'
                            {...register("email", { required: true })}
                        />

                        <div className="relative mb-[14px]">
                            <div className="absolute inset-y-0 start-0 top-0 flex items-center ps-3.5 pointer-events-none">
                                <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 19 18">
                                    <path d="M18 13.446a3.02 3.02 0 0 0-.946-1.985l-1.4-1.4a3.054 3.054 0 0 0-4.218 0l-.7.7a.983.983 0 0 1-1.39 0l-2.1-2.1a.983.983 0 0 1 0-1.389l.7-.7a2.98 2.98 0 0 0 0-4.217l-1.4-1.4a2.824 2.824 0 0 0-4.218 0c-3.619 3.619-3 8.229 1.752 12.979C6.785 16.639 9.45 18 11.912 18a7.175 7.175 0 0 0 5.139-2.325A2.9 2.9 0 0 0 18 13.446Z" />
                                </svg>
                            </div>
                            <input
                                type="text"
                                id="phone-input"
                                aria-describedby="helper-text-explanation"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm lg:w-[791px] w-full h-[43px]
                                      block  ps-10 p-2.5placeholder:text-[#8E8E8E]" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" placeholder="543-456-7890" required
                                {...register("phone", { required: true })}
                            />
                        </div>
                        {/* {
                            showInputFile && (

                                <div className='mb-[14px] relative'>
                                    <label htmlFor="file_input" className="flex items-center cursor-pointer">
                                        <span className="flex items-center w-full text-sm border-[#A5A5A5] bg-white text-gray-900 py-2 px-4 rounded-md border-0  font-semibold">
                                            <img className='bg-white' src={FileIcon} alt="" />
                                            Proje dosyalarını ekle
                                        </span>
                                        <input
                                            id="file_input"
                                            type="file"
                                            className="hidden"
                                        />
                                    </label>
                                </div>
                            )} */}
                        {
                            showTextarea && (
                                <div className='mb-[14px]'>
                                    <textarea
                                        id="message"
                                        rows="4"
                                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 border border-gray-300 " placeholder="İletmek istediğiniz mesajı yazınız"
                                        {...register("message", { required: true })}
                                    />
                                </div>
                            )}
                        <div>
                            <div className="flex items-center mb-4">
                                <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded" />
                                <label htmlFor="default-checkbox" className="ms-2 text-xs font-medium text-white">E-posta ve SMS yoluyla kampanya ve etkinliklerden haberdar olmak istiyorum.</label>
                            </div>

                            {
                                showCheckbox && (

                                    <div>
                                        <div className="flex items-center mb-4">
                                            <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded" />
                                            <label htmlFor="default-checkbox" className="ms-2 text-xs font-medium text-white">Gönüllülük ön şartlarını kabul ediyorum.</label>
                                        </div>
                                        <div className="flex items-center mb-4">
                                            <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded" />
                                            <label htmlFor="default-checkbox" className="ms-2 text-xs font-medium text-white">Kişisel verilere ilişkin aydınlatma metnine{' '}
                                                <a href='#' className='text-sadik-blue'>
                                                    buradan
                                                </a>{' '}
                                                ulaşabilirsiniz.</label>
                                        </div>
                                    </div>
                                )}
                        </div>
                        <button
                            className='bg-sadik-blue hover:bg-sadik-yellow p-2 px-3 w-full mt-4 text-white flex justify-center mx-auto'
                            onClick={handleSubmit(onSubmit)}
                        >
                            {buttonText}
                        </button>

                    </form>
                </div>
            </div>
            <Popup open={open} modal contentStyle={{
                width:  'auto',
                padding: '0px',
                borderWidth: '0px',
                borderRadius: '9px',
            }}>
                <div className="relative w-full max-w-md h-full md:h-auto">
                    <div className="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
                        <button 
                            onClick={() => setOpen(false)}
                            type="button" 
                            className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="successModal">
                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                        <div className="w-12 h-12 rounded-full bg-green-100 dark:bg-green-900 p-2 flex items-center justify-center mx-auto mb-3.5">
                            <svg aria-hidden="true" className="w-8 h-8 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path></svg>
                            <span className="sr-only">Success</span>
                        </div>
                        <p className="mb-4 text-lg font-semibold text-gray-900 dark:text-white">Formunuz Başarı ile Gönderilmiştir!</p>
                    </div>
                </div>
            </Popup>
        </>
    )
}

export default Contact