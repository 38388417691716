import React,{useState,useEffect} from 'react'
import ProjectBanner from '../images/project-banner.png'
import ProjectBannerM from '../images/project-mobile.svg'
import Contact from '../components/Contact'
import Subscribe from '../components/Subscribe'
const Project = () => {

    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
      ]);
    
      useEffect(() => {
        const handleWindowResize = () => {
          setWindowSize([window.innerWidth]);
        };
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      }, []);

    return (
        <>
            <div
                className={
                    'lg:container-full h-[441px] bg-no-repeat bg-center text-white'
                }
                style={windowSize > 768 ?{ backgroundImage: `url("${ProjectBanner}")` } : { backgroundImage: `url("${ProjectBannerM}")` } }
            ></div>
            <Contact
                heading={`Proje ve Hayallerinizi Bizimle Paylaşın`}
                subHeading={`Gebze için var olan hayalleriniz ya da projelerinizi bizimle paylaşın Gebze için birlikte çalışalım.`}
                showTextarea={true}
                buttonText={`Gönder`}
                formName={`Proje Formu`}
            />
            <Subscribe />

        </>
    )
}

export default Project