import React,{useState,useEffect} from 'react'

import Subscribe from '../components/Subscribe';
import Contact from '../components/Contact';


import ContactBanner from '../images/contact-banner.png';
import ContactBannerM from '../images/contact-mobile.svg';

const ContactMe = () => {

    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
      ]);
    
      useEffect(() => {
        const handleWindowResize = () => {
          setWindowSize([window.innerWidth]);
        };
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      }, []);

    return (
        <>
            <div
                className={
                    'container-full h-[441px] bg-no-repeat bg-center text-white'
                }
                style={windowSize > 768 ?{ backgroundImage: `url("${ContactBanner}")` } : { backgroundImage: `url("${ContactBannerM}")` } }
            ></div>


            <Contact
                heading={`Bizimle İletişime Geçin!`}
                subHeading={`Sadık Güvenç ile paylaşmak istediklerinizi aşağıdaki formu doldurarak iletin.`}
                showInputFile={false}
                showTextarea={true}
                buttonText={`Gönder`}
                formName={`İletişim Formu`}
            />
            <Subscribe />
        </>
    );
};

export default ContactMe;
