import React from 'react'
import { Outlet } from "react-router-dom"

import { Navbar } from './Navbar'
import { Footer } from './Footer'

export const LayoutWebsite = () => {
  return (
    <>
        <Navbar/>
        <div id='main' className='w-xl'>
            <Outlet />
        </div>
        <Footer/>
    </>
  )
}
