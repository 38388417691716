import React,{useState,useEffect} from 'react'

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import ProjectsBanner from '../images/projects-banner.png'
import Mobile from '../images/projects-mobile.png'
import SadikGuvenc from '../images/sadik-guvenc.png'
import Summary from '../images/summary.svg'
import Subscribe from '../components/Subscribe'

import Icon1 from '../images/blue-icons/account_balance_wallet.svg'
import Icon2 from '../images/blue-icons/add_reaction.svg'
import Icon3 from '../images/blue-icons/app_shortcut.svg'
import Icon4 from '../images/blue-icons/artist.svg'
import Icon5 from '../images/blue-icons/directions_bus.svg'
import Icon6 from '../images/blue-icons/directions_run.svg'
import Icon7 from '../images/blue-icons/ecg_heart.svg'
import Icon8 from '../images/blue-icons/editor_choice.svg'
import Icon9 from '../images/blue-icons/emergency_heat.svg'
import Icon10 from '../images/blue-icons/emoji_transportation.svg'
import Icon11 from '../images/blue-icons/factory-1.svg'
import Icon12 from '../images/blue-icons/factory.svg'
import Icon13 from '../images/blue-icons/female.svg'
import Icon14 from '../images/blue-icons/pets.svg'
import Icon15 from '../images/blue-icons/school.svg'
import Icon16 from '../images/blue-icons/wheelchair_pickup.svg'

export const Projects = () => {

    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
      ]);
    
      useEffect(() => {
        const handleWindowResize = () => {
          setWindowSize([window.innerWidth]);
        };
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      }, []);

    const Data = [
        {
            icon: Icon8,
            title: "Şehir Markası",
            subtitles: [
                "1- GEBZE   ŞEHİR  MARKASINI OLUŞTURMAK",
                "2-GEBZE KENT FESTİVALLERİNİN DÜZENLENMESİ"
            ]
        },
        {
            icon: Icon2,
            title: "Sosyal Belediyecilik",
            subtitles: [
                    "1- GEBZE AŞEVİ VE MOBİL DAĞITIM HİZMETİ İLE 500.000 İHTİYAÇ SAHİPLERİNE YEMEK HİZMETİ ",
                    "2- İHTİYAÇ NOKTALARINA GEBZE KENT MARKETLERİNİN KURULARAK İHTİYAÇ SAHİPLERİNE UYGUN FİYATTA ÜRÜN SATILMASI",
                    "3-GEBZE KENT LOKANTALARI İLE VATANDAŞLARIMIZIN SAĞLIKLI VE EKONOMİK YEMEK YEMELERİ SAĞLAMAK",
                    "4- GEBZE HALK EKMEĞİ FABRİKASININ AÇILMASI VE ŞATIŞ NOKTALARININ ARTIRILMASI",
                    "5- GEBZE HUZUREVİ VE BAKIM EVİ  KOMPLEKSİ KURULMASI"
            ]
        },
        {
            icon: Icon10,
            title: "Çevre ve Şehircilik",
            subtitles: [
                    "1- YİRMİDÖRTSAAT GEBZE  İLE ŞEHİR MERKEZİNİN YENİLENMESİ VE IŞIKLANDIRILMASI",
                    "2- YEŞİL GEBZE YAŞANABİLİR GELECEK İLE ŞEHİR MERKEZİNİN YEŞİLLENDİRİLMESİ",
                    "3- MAHALLE KONAKLARI",
                    "4- ESKİHİSAR MACERA PARKININ İHTİYAÇA GÖRE DÜZENLENMESİ",
                    "5- MUTLUKENT-MERKEZ -ESKİHİSAR VE KİRAZPINAR-MERKEZ-ESKİHİSAR BİSİKLET YOLLARININ YAPILMASI",
                    "6- GEBZE FUAR MERKEZİ",
                    "7- GEBZE BALIKÇI BARINAĞI VE GEBZE BALIK ÇARŞISININ KURULMASI"
            ]
        },
        {
            icon: Icon15,
            title: "Eğitim",
            subtitles: [
                    "1- ‘SINAVA HAZIRIM ’ İLE 8. VE 12. SINIFLARI ÖZEL MAHALLE ETÜT MERKEZLERİ KURULMASI ",
                    "2- 5000 ÜNİVERSİTELİ ÖĞRENCİYE BURS DESTEĞİ",
                    "3- İLKOKU,ORTAOKUL VE LİSE OLMAK ÜZERE TOPLAM 50.000 ÖĞRENCİYE EĞİTİM DESTEKLERİ",
            ]
        },
        {
            icon: Icon8,
            title: "İstihdam ve Yerel Kalkınma ",
            subtitles: [
                    "1- İŞİN BENDE İLE GEBZE İSTİHDAM OFİSLERİNİN KURULMASI ",
                    "2- GEBZE YATIRIM VE TANITIM AJANSI KURMAK",
            ]
        },
        {
            icon: Icon6,
            title: "Gençlik",
            subtitles: [
                    "1- GENGELECEĞE ADIM ADIM İLE 2000 GENÇ GİRİŞİMCİYE DESTEK PAKET",
                    "2- ÖĞRENCİLERE ÜCRETSİZ ULAŞIM",
                    "3- YENİ EVLENEN 10.000 ÇİFTİMİZE DÜĞÜN DESTEĞİ",
            ]
        },
        {
            icon: Icon11,
            title: "Katılımcı Demokrasi",
            subtitles: [
                    "1- GEBZE GELECEĞE ADIM ADIM İLE ÇÖZÜLMEMİŞ SORUNLARI ÇÖZMEK İÇİN ÇÖZÜM OFİSİNİN KURULMASI",
                    "2- GEBZE MUHTARLAR MECLİSİ",
                    "3- GEBZE GENÇLİK MECLİSİ",
                    "4- GEBZE KENT MECLİSİ"
            ]
        },
    ]

    const Data2 = [
        {
            icon: Icon8,
            title: "Kırsal Kalkınma",
            subtitles: [
                "1- ÜRETEN GEBZE İLE BELEDİYEYE AİT TARIM VE HAYVANCILIK TESİSLERİMİZ KURULMASI",
                "2- MODERN CANLI HAYVAN PAZARININ KURULMASI",
                "3- GEBZE TARIM KART İLE ÇİFTÇİYE VE HAYVANCIYA DESTEK ",
                "4- KIRSAL MAHALLE STATÜSÜ UYGULAMASININ HAYATA GEÇİRİLMESİ"
            ]
        },
        {
            icon: Icon9,
            title: "Deprem ve Afet",
            subtitles: [
                    "1- GEBZE DÖNÜŞÜRYOR İLE BÜYÜK KENTSEL DÖNÜŞÜM HAREKETİNİ BAŞLATARAK ŞEHRİ DOĞAL AFETLERE HAZIRLAMA",
                    "2- GEBZE AFET MERKEZİ VE ŞEHİR AFET YÖNETİM PLANI",
                    "3- DEPREME HAZIRIZ SLOGANI İLE RİSKLİ YAPILARIN DÖNÜŞÜMÜ",
            ]
        },
        {
            icon: Icon13,
            title: "Kadın Hakları",
            subtitles: [
                    "1- ‘HER AN YANINDAYIZ’ İLE 7/24 KADIN DESTEK HATTI KURULMASI",
                    "2- DOĞUM YAPAN KADINLARIMIZA ÜCRETSİZ ULAŞIM İLE YENİ DOĞAN DESTEK KARTI",
                    "3- KADIN EMEĞİ OFİSİ KURULMASI ",
                    "4- KADIN DANIŞMA MERKEZİ KURULMASI",
            ]
        },
        {
            icon: Icon3,
            title: "Bilişim ve Teknoloji",
            subtitles: [
                    "1- ‘FİBER KENT (İNTERNET SORUNU ÇÖZÜMÜ )",
                    "2- ULUSLARARASI STANDARTLARDA E-SPOR MERKEZİ KURULMASI",
                    "3- TEKNOLOJİ SOKAĞI İLE YAZILIM VE TASARIM OFİSLERİ TEŞVİKİ",
            ]
        },
        {
            icon: Icon15,
            title: "Spor",
            subtitles: [
                    "1- ÇOK AMAÇLI SPOR TESİSİ",
                    "2- SPOR KÖYÜ",
                    "3- ESKİHİSAR SU SPORLARI TESİSİ",
                    "4- AMATÖR SPOR KULÜPLERİNE DESTEK İLE GENÇLERİ SPORLA BULUŞTURMAK",
                    "5- GEBZESPOR KOLEJİ",
            ]
        },
        {
            icon: Icon16,
            title: "Engelsiz Yaşam",
            subtitles: [
                    "1- ENGELSİZ ŞEHİR ",
                    "2- ENGELLERİ KALDIRIYORUZ İLE GEBZE ENGELLİ HİZMET MERKEZİ KURULMA",
            ]
        },
        {
            icon: Icon4,
            title: "Kültür ve Sanat",
            subtitles: [
                    "1- KÜLTÜR VE SANAT MECLİSİNİN KURULMASI",
                    "2- KÜLTÜR ŞEHRİ GEBZE ",
                    "3- GEBZE KÖYLERİMİZDE 2 ADET KÜLTÜR MERKEZİNİN YAPILMASI",
            ]
        },
    ]

    const Data3 = [
        {
            icon: Icon11,
            title: "Sanayi",
            subtitles: [
                "1- BELEDİYE SATIN ALMA SİSTEMİNDE GEBZE MARKASI İLE ÜRETİLEN ÜRÜNLERE ÖNCELİK VERİLMESİ",
                "2- BÜYÜK SANAYİ SİTESİ YAPILMASI",
                "3- KÜÇÜK SANAYİ SİTESİNİNİ YENİLENMESİ",
            ]
        },
        {
            icon: Icon5,
            title: "Ulaşım",
            subtitles: [
                    "1- ŞEHİR MERKEZİNDE TRAFİK SORUNUN ÇÖZÜLMESİ",
                    "2- ŞEHİR MERKEZİNDE KATLI OTOPARK YAPILMASI",
                    "3- ŞEHİR MERKEZİNDE RİNG TRAMVAY HATTI",
            ]
        },
        {
            icon: Icon2,
            title: "Mutlu Şehir",
            subtitles: [
                    "1- MADDE BAĞIMLILIĞINA KARŞI REHABİLİTASYON MERKEZİ KURULMASI ",
                    "2- ÇOCUK,GENÇLİK VE AİLE PSİKOLOJİK DANIŞMA SEVİSİ KURULMASI",
            ]
        },
        {
            icon: Icon7,
            title: "Sağlık",
            subtitles: [
                    "1- KÖYLERE HİZMET VERECEK ACİL SAĞLIK ÜSSÜNÜN KURULMASI",
            ]
        },
        {
            icon: Icon14,
            title: "Hayvan Hakları",
            subtitles: [
                    "1- HAYVAN İZLEME OFİSİNİN KURULMASI",
            ]
        },
        {
            icon: Icon1,
            title: "Ekonomi",
            subtitles: [
                    "1- ESNAF DESTEK PROJELERİ",
                    "2- KİRA DESTEĞİ",
            ]
        },
    ]

  return (
    <>
        <div className={"container-full h-[560px] bg-no-repeat bg-center text-black"} style={windowSize > 768 ?{ backgroundImage: `url("${ProjectsBanner}")` } : { backgroundImage: `url("${Mobile}")` } }>
            <div className='max-w-[1280px] mx-auto md:grid hidden md:grid-cols-2 items-center md:pt-24 pb-10 text-white'>
                <div className='md:text-left text-center md:order-first order-last'>
                    <p className='font-bold text-4xl pb-4'>PROJE & VAATLER</p>
                    <p className='text-lg'>Verilen  vaatler ve gerçekleştirilme durumlarnı tüm detayları ile aşağıdaki verilerden takip edebilirsiniz.</p>
                </div>
                <div>
                    <img className='mx-auto' src={SadikGuvenc} alt="" />
                </div>
            </div>
        </div>
        <div className='bg-[#F7F7F7]'>
            <div className='grid md:grid-cols-4 items-center max-w-[1280px] mx-auto py-16'>
                <div className='h-[280px] bg-no-repeat grid w-[226px] mx-auto' style={{ backgroundImage: `url(${Summary})` }}>
                    <p className='text-white text-4xl text-center mt-24 font-bold'>62</p>
                </div>
                <div className='md:col-span-3 md:pb-24 px-4 md:text-left text-center '>
                    <p className='text-sadik-blue text-3xl font-bold md:pt-4 pt-10'>Gebze Yerel Seçimleri</p>
                    <p>Verilen  vaatler ve gerçekleştirilme durumlarnı tüm detayları ile aşağıdaki verilerden takip edebilirsiniz.</p>
                </div>
            </div>
        </div>
        <div className='text-[#606060] max-w-[1280px] mx-auto py-16 text-center md:p-0 p-4'>
            <p className='font-bold text-2xl pb-4'>Gebze için Hayal Ediyoruz!</p>
            <p>Gebze’nin refahı, mutluluğu ve huzuru için üretmeye ve değer yaratmaya devam ediyoruz. Gebze’ye Değer projeleri kıymetli vatandaşlarımız ile paylaşmaktan büyük bir gurur ve mutluluk duyuyoruz.</p>
            <div className='grid md:grid-cols-3 text-left md:gap-4 pt-10'>
            
                <div>
                    <Accordion allowZeroExpanded>
                        {Data.map((data)=>(
                            <AccordionItem key={1}>
                                <AccordionItemHeading>
                                    <AccordionItemButton className='bg-[#C7F8FF] md:p-3 p-2 pl-2 pt-2 hover:bg-[#D8F8FF] items-center'>
                                    <div className='flex'>
                                        <img className='w-[26px]' src={data.icon} alt="" />
                                        <p className='font-bold text-[#38A0D1] pl-2 text-lg'>{data.title}</p>
                                    </div>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                {data.subtitles.map((sub)=>(
                                <AccordionItemPanel>
                                    <div className='border-2 border-gray-300 rounded-lg p-4'>
                                        <p className='text-gray-500 font-semibold pb-2'>{sub}</p>
                                        <Popup trigger={<button className='bg-sadik-blue text-sm text-white hover:bg-sadik-yellow p-1 px-2'><p>Projeye Gözat</p></button>} position="right center">
                                            <div className='text-sm'>Proje detayları yakında sizlerle.</div>
                                        </Popup>
                                    </div>
                                </AccordionItemPanel>
                                ))}
                            </AccordionItem>
                        ))}
                    </Accordion>
                </div>
                <div>
                    <Accordion allowZeroExpanded>
                        {Data2.map((data)=>(
                            <AccordionItem key={1}>
                                <AccordionItemHeading>
                                    <AccordionItemButton className='bg-[#C7F8FF] md:p-3 p-2 pl-2 pt-2 hover:bg-[#D8F8FF] items-center'>
                                    <div className='flex'>
                                        <img className='w-[26px]' src={data.icon} alt="" />
                                        <p className='font-bold text-[#38A0D1] pl-2 text-lg'>{data.title}</p>
                                    </div>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                {data.subtitles.map((sub)=>(
                                <AccordionItemPanel>
                                    <div className='border-2 border-gray-300 rounded-lg p-4'>
                                        <p className='text-gray-500 font-semibold pb-2'>{sub}</p>
                                        <Popup trigger={<button className='bg-sadik-blue text-sm text-white hover:bg-sadik-yellow p-1 px-2'><p>Projeye Gözat</p></button>} position="right center">
                                            <div className='text-sm'>Proje detayları yakında sizlerle.</div>
                                        </Popup>
                                    </div>
                                </AccordionItemPanel>
                                ))}
                            </AccordionItem>
                        ))}
                    </Accordion>
                </div>
                <div>
                    <Accordion allowZeroExpanded>
                        {Data3.map((data)=>(
                            <AccordionItem key={1}>
                                <AccordionItemHeading>
                                    <AccordionItemButton className='bg-[#C7F8FF] md:p-3 p-2 pl-2 pt-2 hover:bg-[#D8F8FF] items-center'>
                                    <div className='flex'>
                                        <img className='w-[26px]' src={data.icon} alt="" />
                                        <p className='font-bold text-[#38A0D1] pl-2 text-lg'>{data.title}</p>
                                    </div>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                {data.subtitles.map((sub)=>(
                                <AccordionItemPanel>
                                    <div className='border-2 border-gray-300 rounded-lg p-4'>
                                        <p className='text-gray-500 font-semibold pb-2'>{sub}</p>
                                        <Popup trigger={<button className='bg-sadik-blue text-sm text-white hover:bg-sadik-yellow p-1 px-2'><p>Projeye Gözat</p></button>} position="right center">
                                            <div className='text-sm'>Proje detayları yakında sizlerle.</div>
                                        </Popup>
                                    </div>
                                </AccordionItemPanel>
                                ))}
                            </AccordionItem>
                        ))}
                    </Accordion>
                </div>
            
            </div>
        </div>
        {/* <div className='text-[#606060] max-w-[1280px] mx-auto py-16 text-center md:p-0 p-4'>
            <p className='font-bold text-2xl pb-4'>Gebze için Hayal Ediyoruz!</p>
            <p>Gebze’nin refahı, mutluluğu ve huzuru için üretmeye ve değer yaratmaya devam ediyoruz. Gebze’ye Değer projeleri kıymetli vatandaşlarımız ile paylaşmaktan büyük bir gurur ve mutluluk duyuyoruz.</p>
            <div className='grid md:grid-cols-3 md:gap-4 pt-10'>
                <div className='bg-[#C7F8FF] md:p-3 pl-2 pt-2'>
                    <div className='flex'>
                        <img className='w-[26px]' src={Icon16} alt="" />
                        <p className='font-bold text-[#38A0D1] pl-2 pb-2 text-lg'>Engelsiz Yaşam</p>
                    </div>
                    <div className='flex'>
                        <img className='w-[26px]' src={Icon8} alt="" />
                        <p className='font-bold text-[#38A0D1] pl-2 pb-2 text-lg'>Şehir Markası</p>
                    </div>
                    <div className='flex'>
                        <img className='w-[26px]' src={Icon14} alt="" />
                        <p className='font-bold text-[#38A0D1] pl-2 pb-2 text-lg'>Hayvan Hakları</p>
                    </div>
                    <div className='flex'>
                        <img className='w-[26px]' src={Icon10} alt="" />
                        <p className='font-bold text-[#38A0D1] pl-2 pb-2 text-lg'>Çevre ve Şehircilik</p>
                    </div>
                    <div className='flex'>
                        <img className='w-[26px]' src={Icon2} alt="" />
                        <p className='font-bold text-[#38A0D1] pl-2 pb-2 text-lg'>Sosyal Belediyecilik</p>
                    </div>
                </div>
                <div className='bg-[#C7F8FF] md:p-3 pl-2'>
                    <div className='flex'>
                        <img className='w-[26px]' src={Icon13} alt="" />
                        <p className='font-bold text-[#38A0D1] pl-2 pb-2 text-lg'>Kadın Hakları</p>
                    </div>
                    <div className='flex'>
                        <img className='w-[26px]'src={Icon15} alt="" />
                        <p className='font-bold text-[#38A0D1] pl-2 pb-2 text-lg'>Eğitim</p>
                    </div>
                    <div className='flex'>
                        <img className='w-[26px]' src={Icon12} alt="" />
                        <p className='font-bold text-[#38A0D1] pl-2 pb-2 text-lg'>Katılımcı Demokrasi</p>
                    </div>
                    <div className='flex'>
                        <img className='w-[26px]' src={Icon4} alt="" />
                        <p className='font-bold text-[#38A0D1] pl-2 pb-2 text-lg'>Kültür ve Sanat</p>
                    </div>
                    <div className='flex'>
                        <img className='w-[26px]' src={Icon6} alt="" />
                        <p className='font-bold text-[#38A0D1] pl-2 pb-2 text-lg'>Gençlik</p>
                    </div>
                </div>
                <div className='bg-[#C7F8FF] md:p-3 pl-2 pb-2'>
                    
                    <div className='flex'>
                        <img  className='w-[26px]'src={Icon1} alt="" />
                        <p className='font-bold text-[#38A0D1] pl-2 pb-2 text-lg'>Ekonomi</p>
                    </div>
                    <div className='flex'>
                        <img className='w-[26px]' src={Icon3} alt="" />
                        <p className='font-bold text-[#38A0D1] pl-2 pb-2 text-lg'>Bilişim ve Teknoloji</p>
                    </div>
                    <div className='flex'>
                        <img className='w-[26px]' src={Icon11} alt="" />
                        <p className='font-bold text-[#38A0D1] pl-2 pb-2 text-lg'>Sanayi</p>
                    </div>
                    <div className='flex'>
                        <img className='w-[26px]' src={Icon7} alt="" />
                        <p className='font-bold text-[#38A0D1] pl-2 pb-2 text-lg'>Sağlık</p>
                    </div>
                    <div className='flex'>
                        <img className='w-[26px]' src={Icon9} alt="" />
                        <p className='font-bold text-[#38A0D1] pl-2 pb-2 text-lg'>Deprem ve Afet</p>
                    </div>
                    <div className='flex'>
                        <img className='w-[26px]' src={Icon5} alt="" />
                        <p className='font-bold text-[#38A0D1] pl-2 pb-2 text-lg'>Ulaşım</p>
                    </div>
                </div>
            </div>
        </div> */}
        <Subscribe/>
    </>
  )
}
