import React from 'react'

import Logo from '../images/logo.svg'
import Logo2 from '../images/footer-logo.svg'

import XIcon from '../images/twitter.svg'
import IGIcon from '../images/instagram.svg'
import FBIcon from '../images/facebook.svg'
import YTIcon from '../images/youtube.svg'

export const Footer = () => {
  return (
    <div className="bottom-0 bg-[#002940] w-full py-10 px-4 text-white">
      <div className='grid md:grid-cols-3 max-w-[1280px] mx-auto'>
        <div className='grid grid-cols-2 items-center'>
          <div>
            <img className='mx-auto md:pl-0 pl-4 pr-10' src={Logo} alt="" />
          </div>
          <div className='border-l-2 pl-5'>
            <img className='md:mx-0 mx-auto' src={Logo2} alt="" />
          </div>
          <div className='col-span-2 text-sm md:text-left text-center pt-2 pl-4 md:mx-0 mx-auto max-w-[305px]'>
            <p>Gebze Kazanacak,<br/> Gebze Tarih Yazacak.</p>
          </div>
        </div>
        <div className='md:grid flex-wrap hidden gap-4 items-center md:pt-0 pt-2 mx-auto'>
          <div className='flex'>
            <a href="#"><p className='pr-6'>Bilgi Güvenliği ve Çerez Politikası</p></a>
            <a href="#"><p>Aydınlatma Metni</p></a>
          </div>
          <p className='text-center'>Sadık Güvenç Resmi Web Sayfası<br/>
          Sadık Güvenç © 2024.</p>
        </div>
        <div className='flex items-center gap-6 md:py-0 py-6 mx-auto'>
          <a href="https://x.com/sadikguvenc_?s=21"><img src={XIcon} alt="" /></a>
          <a href="https://www.instagram.com/sadikguvenc_?igsh=enFudmY1MXhndmZ4&utm_source=qr"><img src={IGIcon} alt="" /></a>
          <a href="https://www.facebook.com/profile.php?id=100025753653432&mibextid=uzlsIk"><img src={FBIcon} alt="" /></a>
          <a href=""><img src={YTIcon} alt="" /></a>
        </div>
        <div className='md:hidden block text-center pt-2 gap-4 text-sm items-center mx-auto'>
        <p className='text-center pb-4'>Sadık Güvenç Resmi Web Sayfası<br/>
          Sadık Güvenç © 2024.</p>
          <a href=""><p>Bilgi Güvenliği ve Çerez Politikası</p></a>
          <a href=""><p>Aydınlatma Metni</p></a>
        </div>

      </div>

    </div>
  )
}
