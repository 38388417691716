import React,{useState,useEffect} from 'react'
import VolunteerBanner from '../images/volunteer.png'
import VolunteerBannerM from '../images/volunteer-mobile.svg'

import Contact from '../components/Contact'
import Subscribe from '../components/Subscribe'
const Volunteer = () => {

    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
      ]);
    
      useEffect(() => {
        const handleWindowResize = () => {
          setWindowSize([window.innerWidth]);
        };
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      }, []);

    return (
        <>
            <>
                <div
                    className={
                        'lg:container-full h-[400px] bg-no-repeat bg-center text-white'
                    }
                    style={windowSize > 768 ?{ backgroundImage: `url("${VolunteerBanner}")` } : { backgroundImage: `url("${VolunteerBannerM}")` } }
                ></div>
                <Contact
                    heading={`GÖNÜLLÜLÜK FORMU`}
                    subHeading={`Gönüllü olmak için aşağıdaki alanları doldurunuz.`}
                    showTextarea={false}
                    buttonText={`Gönüllü Ol`}
                    showCheckbox={true}
                    showInputFile={false}
                    formName={`Gönüllülük Formu`}
                />
                <Subscribe />

            </>
        </>
    )
}

export default Volunteer